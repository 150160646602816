import React, { useState, useEffect } from 'react';

const EditablePopupContent = ({
  spot,
  onUpdate,
  onDelete,
  setSpotBeingEditedForLocation,
}) => {
  const [editedSpot, setEditedSpot] = useState(spot);

  // Handle input change in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedSpot((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission to update the spot
  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(spot.id, editedSpot);
  };

  // Handle deletion of the spot
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this surf spot?')) {
      onDelete(spot.id);
    }
  };

  // Handle initiating location edit
  const handleEditLocation = () => {
    setSpotBeingEditedForLocation(spot);
  };

  useEffect(() => {
    setEditedSpot((prevEditedSpot) => ({
      ...prevEditedSpot,
      lat: spot.lat,
      lng: spot.lng,
      orientation: spot.orientation,
      bearing: spot.bearing,
    }));
  }, [spot.lat, spot.lng, spot.orientation, spot.bearing]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="info-grid">
        <div className="name-field">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            className="name-input"
            value={editedSpot.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div className="bottom-type-field">
          <label>Bottom Type:</label>
          <input
            type="text"
            name="bottomType"
            className="bottom-type-input"
            value={editedSpot.bottomType}
            onChange={handleChange}
            placeholder="Bottom Type"
          />
        </div>
      </div>
      <div className="textarea-container">
        <label>Local Knowledge:</label>
        <textarea
          name="localKnowledge"
          value={editedSpot.localKnowledge}
          onChange={handleChange}
          placeholder="Local Knowledge"
          rows="4"
        />
      </div>
      <div className="info-grid four-columns">
        <div>
          <label>Latitude:</label>
          <span>{editedSpot.lat.toFixed(6)}</span>
        </div>
        <div>
          <label>Longitude:</label>
          <span>{editedSpot.lng.toFixed(6)}</span>
        </div>
        <div>
          <label>Orientation:</label>
          <span>{editedSpot.orientation}</span>
        </div>
        <div>
          <label>Bearing:</label>
          <span>{editedSpot.bearing}°</span>
        </div>
      </div>
      <div className="button-container">
        <button
          type="button"
          onClick={handleEditLocation}
          className="edit-location-button"
        >
          Edit Location
        </button>
        <button type="submit">Save Changes</button>
        <button
          type="button"
          onClick={handleDelete}
          className="delete-button"
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default EditablePopupContent;
