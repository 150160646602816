import React, { useState, useEffect } from 'react';

const NewSpotForm = ({ spot, onAdd, onCancel }) => {
  const [newSpot, setNewSpot] = useState({
    name: '',
    bottomType: '',
    localKnowledge: '',
  });

  // Update form fields with clicked coordinates when the spot is clicked
  useEffect(() => {
    setNewSpot((prevSpot) => ({
      ...prevSpot,
      lat: spot.lat.toFixed(6), // Set latitude to the clicked location
      lng: spot.lng.toFixed(6), // Set longitude to the clicked location
    }));
  }, [spot]);

  // Handle input changes for new spot form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSpot((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission to add a new spot
  const handleSubmit = (e) => {
    e.preventDefault();
    const spotToAdd = {
      ...newSpot,
      lat: parseFloat(spot.lat), // Parse latitude as float
      lng: parseFloat(spot.lng), // Parse longitude as float
    };
    onAdd(spotToAdd); // Call the add function with the new spot data
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="info-grid">
        <div className="name-field">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={newSpot.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div className="bottom-type-field">
          <label>Bottom Type:</label>
          <input
            type="text"
            name="bottomType"
            value={newSpot.bottomType}
            onChange={handleChange}
            placeholder="Bottom Type"
          />
        </div>
      </div>
      <div className="textarea-container">
        <label>Local Knowledge:</label>
        <textarea
          name="localKnowledge"
          value={newSpot.localKnowledge}
          onChange={handleChange}
          placeholder="Local Knowledge"
          rows="4"
        />
      </div>
      <div className="info-grid">
        <div>
          <label>Latitude:</label>
          <span>{spot.lat.toFixed(6)}</span>
        </div>
        <div>
          <label>Longitude:</label>
          <span>{spot.lng.toFixed(6)}</span>
        </div>
      </div>
      <div className="button-container">
        <button
          type="submit"
          style={{ width: '100%', boxSizing: 'border-box' }}
        >
          Add Surf Spot
        </button> {/* Submit button */}
        <button
          type="button"
          onClick={onCancel}
          style={{ width: '100%', boxSizing: 'border-box' }}
        >
          Cancel
        </button> {/* Cancel button */}
      </div>
    </form>
  );
};

export default NewSpotForm;
