// src/pages/SignupPage.js
import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import '../styles.css'; // Import the styles

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      // Firebase method to create a new user
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");  // Redirect to dashboard after successful signup
    } catch (error) {
      setError("📝 Oops! Wipeout! Failed to create an account. Please try again.");
      console.error("Error signing up: ", error.message);
    }
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <h1>📝 Create an Account</h1>
      <form onSubmit={handleSignup} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div style={{ height: '20px' }} /> {/* Padding added here */}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div style={{ display: 'flex', gap: '10px' }}> {/* Flex container for buttons */}
          <button type="submit" style={{ width: 'auto' }}>📝 Ride the Wave</button>
          <button type="button" onClick={goToLogin} style={{ width: 'auto' }}>🔙 Paddle Back to Login</button>
        </div>
      </form>
    </div>
  );
};

export default SignupPage;
