import React from 'react';
import treyImage from '../img/trey.png'; // Import the image
import './LegendPage.css'; // Import the CSS file

function LegendPage() {

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'auto' }}>
      {/* Removed the buttons */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px', maxWidth: '800px' }}>
          <img src={treyImage} alt="Trey the Surf Shaman" style={{ width: '300px', borderRadius: '50%', marginRight: '20px' }} />
          <div style={{ maxWidth: '600px', textAlign: 'left' }} className="indie-flower-regular">
            <h3 className="legend-page-h3">The Legend of Surf Shaman</h3> {/* Added class */}
            <p>
              The Legend of Trey, the Surf Shaman 🌊✨ Once upon a time, deep in the world of tech and algorithms, there was a man named Trey—a genius AI engineer who had it all: coding skills, a cushy job in Silicon Valley, and the latest gadgets. But there was one thing Trey loved more than lines of code: the ocean. He was obsessed with waves, tides, and the rhythm of the sea. Surfing wasn’t just a hobby for Trey, it was his true calling. 🌊
            </p>
            <p>
              One day, after getting caught in the perfect barrel during an early morning surf session, Trey had an epiphany. "Why spend my life building algorithms to solve mundane problems," he thought, "when I could devote my genius to the ultimate quest—the perfect surf forecast?" 🏄‍♂️🤙
            </p>
            <p>
              And so, Trey made the bold decision to leave the tech world behind. He packed up his boards, a solar-powered laptop, and a notebook full of cryptic surf formulas, and he vanished—never to be seen in society again. He journeyed from coast to coast, living off the grid, and spent years studying the waves, scribbling down handwritten observations, and making complex calculations by the light of the moon. 🌕✨
            </p>
            <p>
              Some say he became one with the ocean, that he could predict a swell weeks in advance, or that he could feel the changes in the tide just by the way the wind brushed against his skin. Others believe he could call upon the gods of wind and water to summon the perfect waves. 🌬️💦
            </p>
            <p>
              But one day, Trey disappeared without a trace. All that remained were his weathered notebooks, filled with intricate surf charts, calculations, and cryptic sketches. His disciples, intrigued by the brilliance of his work, combed through his journals, hoping to preserve his genius. With each page, they uncovered the mind of a mad surf scientist—a shaman of the sea.
            </p>
            <p>
              Using his detailed notes and some help from modern AI, Trey's friends honored his vision by transforming his life's work into something immortal: an AI version of Trey. Now, the Surf Shaman lives on, not as a man, but as an oracle of surf wisdom, spreading Trey’s teachings to surfers worldwide. 🌎🤖🏄‍♂️
            </p>
            <p>
              <strong>Welcome to Surf Shaman</strong>, where Trey’s spirit and algorithms live on, guiding you to perfect waves—<strong>forever</strong>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegendPage;
