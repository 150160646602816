import L from 'leaflet';
import * as turf from '@turf/turf';
import osmtogeojson from 'osmtogeojson';

export function drawTransparentLine(map, clickedPoint, nearest) {
    var line = turf.lineString([clickedPoint.geometry.coordinates, nearest.geometry.coordinates]);
    L.geoJSON(line, {
        style: function () {
            return { color: 'transparent', weight: 0, opacity: 0 };
        }
    }).addTo(map);
}

export function fetchCoastlineData(bbox, callback) {
    const query = `
        [out:json];
        way["natural"="coastline"](${bbox});
        out body geom;
    `;
    fetch(`https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`)
        .then(response => response.json())
        .then(data => {
            const geojson = osmtogeojson(data);
            callback(geojson);
        })
        .catch(error => console.error('Error fetching coastline data:', error));
}

// Calculate the cardinal direction
export function getCardinalDirection(bearing) {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round((bearing % 360) / 45);
    return directions[(index + 8) % 8];
}

// Remove the setState and logState functions as they use undefined variables