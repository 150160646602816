import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom"; // Remove BrowserRouter and Router
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import DashboardPage from "./pages/DashboardPage";
import ProtectedRoute from "./ProtectedRoute";  // Import ProtectedRoute
import { AuthProvider } from "./AuthContext";
import LegendPage from './pages/LegendPage';
import NavBar from "./NavBar"; // Import NavBar
import './App.css'; // Import the CSS file
import SurferInfo from "./pages/SurferInfo"; // Add this import
import AdventureMap from './pages/AdventureMap'; // Correct the import path

function App() {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    let title = "Shaman Surf"; // Default title

    switch (pathname) {
      case "/":
        title = "Home - Shaman Surf";
        break;
      case "/login":
        title = "Login - Shaman Surf";
        break;
      case "/signup":
        title = "Signup - Shaman Surf";
        break;
      case "/dashboard":
        title = "Dashboard - Shaman Surf";
        break;
      case "/surfer-info":
        title = "Surfer Info - Shaman Surf";
        break;
      case "/adventure-map":
        title = "Adventure Map - Shaman Surf";
        break;
      default:
        title = "Shaman Surf";
    }

    document.title = title;
  }, [location]);

  return (
    <AuthProvider>
      <NavBar /> {/* Add NavBar here */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/legend" element={<LegendPage />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/surfer-info"
            element={
              <ProtectedRoute>
                <SurferInfo />
              </ProtectedRoute>
            }
          />
          <Route path="/adventure-map" element={<AdventureMap />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
