// src/pages/DashboardPage.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { deleteUser, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const { user } = useAuth();  // Get the current user from AuthContext
  const navigate = useNavigate();
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    const fetchIdToken = async () => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setIdToken(token);
        } catch (error) {
          console.error("Error fetching ID token:", error);
        }
      }
    };

    fetchIdToken();
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);  // Firebase sign out method
      navigate("/login");   // Redirect to the login page after logout
    } catch (error) {
      console.error("Error logging out: ", error.message);
      alert("🌊 Oops! Wipeout while logging out. Please try again.");
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone. Catch you on the flip side!")) {
      try {
        await deleteUser(auth.currentUser);  // Firebase delete account method
        alert("🏄‍♂️ Account deleted successfully. Ride the waves!");
        navigate("/");  // Redirect to home page after deletion
      } catch (error) {
        console.error("Error deleting account: ", error.message);
        alert("🌊 Oops! Wipeout while deleting account. Please log in again and try.");
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <h1>Account Settings</h1>
      {user ? (
        <div style={{ textAlign: 'center' }}>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>UID:</strong> {user.uid}</p>
          <p><strong>User Token:</strong></p>
          {idToken && (
            <textarea
              value={idToken}
              readOnly
              style={{ width: '80%', height: '100px', wordBreak: 'break-all' }}
            />
          )}
          <p><i>Copy your user token to use it in your API requests.</i></p>
          <button onClick={handleLogout} style={{ marginRight: "10px", width: 'auto' }}>
            🚪 Logout
          </button>
          <button onClick={handleDeleteAccount} style={{ backgroundColor: "red", color: "white", width: 'auto' }}>
            🗑️ Delete My Account
          </button>
        </div>
      ) : (
        <p>Loading user information...</p>
      )}
    </div>
  );
};

export default DashboardPage;
