// src/pages/HomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import bigWave from '../img/big_wave.png';

function HomePage() {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  const goToLegend = () => {
    navigate('/legend');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <h1>Welcome to Surf Shaman 🌊</h1>
      <img src={bigWave} alt="Big Wave" style={{ width: '450px', height: 'auto' }} /> {/* Added image */}
      <h3 style={{ color: 'purple' }}>Drop in and catch some wave knowledge!</h3> {/* Ensure h3 is purple */}
      <div style={{ display: 'flex', gap: '10px' }}> {/* Flex container for buttons */}
        <button onClick={goToLogin} style={{ width: 'auto' }}>🏄 Drop in</button> {/* Changed emoji */}
        <button onClick={goToLegend} style={{ width: 'auto', backgroundColor: 'purple', color: 'white' }}>📜Read the Legend</button>
      </div>
    </div>
  );
}

export default HomePage;
