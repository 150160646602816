import React from "react";
import { useAuth } from "./AuthContext";
import { auth } from "./firebase";
import { useNavigate, useLocation } from "react-router-dom";
import './NavBar.css'; // Import the CSS file

const NavBar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="nav-container">
      <button
        className={`nav-button ${isActive("/") ? "active" : ""}`}
        onClick={() => navigate("/")}
      >
        🏠 Home
      </button>
      {user && (
        <>
          <button
            className={`nav-button ${isActive("/surfer-info") ? "active" : ""}`}
            onClick={() => navigate("/surfer-info")}
          >
            🏄 Surfer Info
          </button>
          <button
            className={`nav-button ${isActive("/adventure-map") ? "active" : ""}`}
            onClick={() => navigate("/adventure-map")}
          >
            🗺️ Adventure Map
          </button>
          <button
            className={`nav-button ${isActive("/legend") ? "active" : ""}`}
            onClick={() => navigate("/legend")}
          >
            📜 Read the Legend
          </button>
          <button
            className={`nav-button ${isActive("/dashboard") ? "active" : ""}`}
            onClick={() => navigate("/dashboard")}
          >
            🗂️ Account
          </button>
          <button
            className="nav-button"
            onClick={() => auth.signOut()}
          >
            🔓 Logout
          </button>
        </>
      )}
      {!user && (
        <button
          className={`nav-button ${isActive("/login") ? "active" : ""}`}
          onClick={() => navigate("/login")}
        >
          🔑 Login
        </button>
      )}
    </nav>
  );
};

export default NavBar;
