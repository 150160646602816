// src/pages/LoginPage.js
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import '../styles.css'; // Import the styles

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Firebase login method
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      setError("🌊 Wipeout! Failed to log in. Please check your credentials.");
      console.error("Error logging in: ", error.message);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <h1>🔒 Surf's Up! Log In</h1>
      <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div style={{ height: '20px' }} /> {/* Padding added here */}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit" style={{ width: 'auto' }}>🔑 Login</button>
      </form>
      <p><em>Don't have an account? <Link to="/signup" className="link">Create one</Link></em></p> {/* Italicized text with custom link style */}
    </div>
  );
};

export default LoginPage;
