// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcrB8Eb2xBY3f8P3mcGXyffk9jGRuPPzw",
  authDomain: "shaman-surf.firebaseapp.com",
  projectId: "shaman-surf",
  storageBucket: "shaman-surf.appspot.com",
  messagingSenderId: "931732566964",
  appId: "1:931732566964:web:b680fade4ffedafdf2b8e1",
  measurementId: "G-WN16ZT8GTJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);  // This exports the 'auth' object