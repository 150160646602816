import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged

function SurferInfo() {
    console.log('SurferInfo component rendered');
    const [formData, setFormData] = useState({
        name: '',
        quiver: '',
        gear: '',
        experience: '',
        conditions: ''
    });
    const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState(''); // State for modal message

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user);
            } else {
                console.error('User is not authenticated');
            }
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []);

    const fetchUserData = async (user) => {
        const userId = user.uid;
        console.log('Fetching data for user ID:', userId);

        try {
            const firebaseToken = await user.getIdToken();
            const response = await fetch(`https://surfer-info-worker.francis-7b1.workers.dev/?user_id=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${firebaseToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const data = await response.json();
                    setFormData(data); // Populate form with fetched data
                } else {
                    const textResponse = await response.text();
                    if (textResponse === "User not found") {
                        console.log('No data found for this user. Initializing with default values.');
                        // Optionally, you can set default values or handle this case as needed
                    } else {
                        console.error('Unexpected response:', textResponse);
                    }
                }
            } else {
                console.error('Failed to fetch user data:', await response.text());
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to fetch the Firebase token
    const getFirebaseToken = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            return await user.getIdToken();
        } else {
            throw new Error("User is not authenticated");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const firebaseToken = await getFirebaseToken(); // Use the defined function to get the token
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            console.error('User is not authenticated');
            return;
        }

        const response = await fetch('https://surfer-info-worker.francis-7b1.workers.dev', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${firebaseToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: user.uid, // Use the actual user ID from Firebase
                ...formData
            })
        });

        if (response.ok) {
            console.log('Data submitted successfully');
            setModalMessage('Your information has been updated!');
            setIsModalVisible(true); // Show modal on success
            console.log('Modal should be visible now');
        } else {
            console.error('Error submitting data');
            setModalMessage('There was an error submitting your information. Please try again.');
            setIsModalVisible(true); // Show modal on error
            console.log('Modal should be visible now');
        }
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{
            position: 'absolute',
            top: '60px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '24px', // Adjust as needed
            marginTop: '20px', // Adjust as needed
            padding: '20px', // Add padding
            display: 'grid',
            gridTemplateColumns: '1fr 1fr', // Two columns
            gap: '20px' // Space between grid items
        }}>
            <h2 style={{ gridColumn: 'span 2' }}>My Info 🌅</h2>
            <p style={{ gridColumn: 'span 2', fontSize: '15px', width: '600px', margin: '0 auto' }}>
                Help us create a surf report that's uniquely yours! Please take a moment to fill in the details below. <strong style={{ color: 'white' }}>The more information you provide about your quiver, gear, surfing experience, and ideal conditions, the better we can customize your report.</strong> 
            </p>
            <p style={{ gridColumn: 'span 2', fontSize: '15px', width: '600px', margin: '0 auto' }}>
            Feel free to write in long form—don’t hold back on sharing the specifics that make your surf style unique. From your <span style={{ color: 'green' }}>board preferences</span> to the type of <span style={{ color: 'green' }}>waves</span> you love, every detail helps us capture the essence of your perfect session! 🏄‍♂️
            </p>
            <form onSubmit={handleSubmit} style={{
                gridColumn: 'span 2',
                width: '600px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr', // Two columns
                gap: '20px' // Space between grid items
            }}>
                <label style={{ fontSize: '20px', gridColumn: 'span 2' }}>
                    Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Quiver 🏹:
                    <textarea 
                        name="quiver"
                        value={formData.quiver}
                        onChange={handleChange}
                        placeholder="Describe all the boards in your quiver. What’s your go-to board for certain conditions? Any custom shapes or special features?" 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Gear 🔧:
                    <textarea 
                        name="gear"
                        value={formData.gear}
                        onChange={handleChange}
                        placeholder="What other gear do you use? Mention wetsuits, leashes, fins, wax preferences, and anything that’s part of your setup." 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Surfing Experience 🌍:
                    <textarea 
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                        placeholder="How long have you been surfing? Share your skill level, favorite breaks, memorable sessions, and any surf travels." 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <label style={{ fontSize: '20px' }}>
                    My Ideal Conditions 🌤️:
                    <textarea 
                        name="conditions"
                        value={formData.conditions}
                        onChange={handleChange}
                        placeholder="Describe your dream surf session! What wave size, direction, wind conditions, and tide work best for you?" 
                        style={{ height: '150px', width: '90%' }}>
                    </textarea>
                </label>
                <button type="submit" style={{ gridColumn: 'span 2', backgroundColor: 'purple', color: 'white', fontSize: '20px' }}>Submit 🚀</button>
            </form>

            {/* Modal Implementation */}
            {isModalVisible && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'black',
                    padding: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    zIndex: 1000,
                    borderRadius: '10px' // Add rounded corners
                }}>
                    <p style={{ color: 'white', fontSize: '14px' }}>{modalMessage}</p> {/* Make text black and smaller */}
                    <button onClick={closeModal} style={{ backgroundColor: 'purple', color: 'white', fontSize: '16px' }}>Close</button>
                </div>
            )}
        </div>
    );
}

export default SurferInfo;